<template>
    <custom-table
        :title="$t('pages.localization.tax.title')"
        :subTitle="$t('pages.localization.tax.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchTax(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_tax" ref="addTaxModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="taxForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.country') }}</label>
                                <el-form-item prop="country_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.country_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(country, countryIndex) in countries" :key="countryIndex" :value="country.id" :label="country.name">{{ country.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.rate') }}</label>
                                <el-form-item prop="rate" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.rate" v-mask="{ alias: 'decimal', placeholder: '', allowMinus:false, rightAlign: false }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.localization.tax.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.localization.tax.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.localization.tax.cols.code"),
                    key: "code"
                },
                {
                    name: this.$t("pages.localization.tax.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("pages.localization.tax.cols.rate"),
                    key: "rate"
                },
                {
                    name: this.$t("pages.module.teacher.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.module.teacher.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.localization.tax.table;
        },
        countries() {
            return this.$store.state.localization.country.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.localization.tax.title"), [this.$t("menu.localizationManagement")]);
        this.$store.dispatch('localization/tax/get', {page: {}});
        this.$store.dispatch('localization/country/get', {
            page: {pageSize: 9999}
        });
    },
    methods:{
        newTax(){
            this.form.updateStatus = false;
            this.form.data = {
                sort: 1,
                active: true
            };
            this.form.title = this.$t("pages.localization.tax.newTax");
            this.showModal(this.$refs.addTaxModal);
        },
        fetchTax(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.localization.tax.editTax");
            this.axios.get(this.endpoints['localization_tax']+ '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs.addTaxModal);
            });
        },
        onSubmit(){
            this.$refs.taxForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['localization_tax'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addTaxModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['localization_tax'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addTaxModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("localization/tax/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newTax();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("localization/tax/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("localization/tax/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>